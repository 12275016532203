import Service from '@/helpers/service'

export default {

    sync(params) {
        return Service().get('integration/sap/sync', {params});
    },
    execute(id) {
        return Service().get('integration/sap/'+id+'?force=1');
    },
    configSync(page,params){
        return Service().get('integration/config/sap-sync?page=' + page, {params});
    },
    showConfigSync(params){
        return Service().get('integration/config/sap-sync-show',{params});
    },
    createSync(params){
        return Service().post('integration/config/sap-sync/create',params);
    },
    updateSync(params){
        return Service().post('integration/config/sap-sync/update' , params);
    },
    createParam(params){
        return Service().post('integration/config/sap-sync-params/create' , params);
    },
    getParams(page, params){
        return Service().get('integration/config/sap-sync-params?page=' + page, {params});
    },
    editParam(id, params){
        return Service().post('integration/config/sap-sync-params/update/' + id, params);
    },
    deleteParams(id){
        return Service().post('integration/config/sap-sync-params/delete/' + id);
    },
    showParam(id){
        return Service().get('integration/config/sap-sync-params/' + id);
    },
    getParamMapping(id){
        return Service().get('integration/config/sap-sync-params-mapping/' + id);
    },
    saveParamMapping(id,params){
        return Service().post('integration/config/sap-sync-params-mapping/save/'+ id, params);
    },
    getTableObjects(params){
        return Service().get('integration/config/table-objects', {params})
    }
}