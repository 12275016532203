<template>
    <div v-if="authUserPermission['sap-connection-view']">
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header mt--4 mb--4">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0"><button class="btn btn-outline-primary btn-sm font-weight-bold text-muted mr-2" @click="backPage"><i class="fas fa-long-arrow-alt-left"></i></button>{{ tt('sap_sync_params') }}</h3>
                        </div>
                        <div class="col-6">
                            <base-input input-classes="form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search_sap_sync_params')" v-on:keyup="filter"/>
                        </div>
                        <div class="col text-right">
                            <base-button size="sm" type="default" @click="create">{{ tt('add_new') }}</base-button>
                        </div>
                    </div>
                </div>
                <div>
                    <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                        <el-table-column :label="tt('sap_sync_id')" :prop="tt('sap_sync_id')" min-width="180px" sortable>
                            <template v-slot="{row}">
                                {{row.sap_sync_id}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('param_type')" :prop="tt('param_type')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                {{row.param_type}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('data_type')" :prop="tt('data_type')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                {{row.data_type}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('source_type')" :prop="tt('source_type')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                {{row.source_type}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('source')" :prop="tt('source')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                {{row.source}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('source_key')" :prop="tt('source_key')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                {{row.source_key}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('sap_ref')" :prop="tt('sap_ref')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                {{row.sap_ref}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('sap_key')" :prop="tt('sap_key')" min-width="170px" sortable>
                            <template v-slot="{row}">
                                {{row.sap_key}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="action" width="100">
                            <template v-slot="{row}">
                                <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true">
                                    <span class="btn btn-sm btn-icon-only text-light">
                                        <i class="fas fa-ellipsis-v mt-2"></i>
                                    </span>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <el-dropdown-item :command="{action:'edit', data:row.id}">{{ tt('edit') }}</el-dropdown-item>
                                        <el-dropdown-item :command="{action:'remove', data:row.id}">{{ tt('delete') }}</el-dropdown-item>                                        
                                        <el-dropdown-item :command="{action:'mapping', data:{id:row.id, source:row.source}}">{{ tt('mapping') }}</el-dropdown-item>                                        
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                <skeleton-loading v-else/>
                </div>                 
                <div class="card-footer pb-0 ">   
                    <span class="float-left">
                        <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                    </span>                             
                    <span class="float-right">
                        {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                    </span>
                </div>
            </div>
        </div>
        <validation-observer v-slot="{invalid}">
            <modal :show.sync="form.show">
                <template slot="header">
                    <h5 class="modal-title">{{form.title}}</h5>
                </template>
                <div>
                    <label class="form-control-label">{{ tt('sap_sync_id') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('sap_sync_id')" v-model="sapSyncParam.sap_sync_id" :placeholder="tt('sap_sync_id')" disabled rules="required">
                        <!-- <el-select v-model="sapSyncParam.sap_sync_id" :placeholder="tt('sap_sync_id')" filterable clearable remote :remote-method="searchSync" reserve-keyword>
                            <el-option v-for="sync in sapSyncs" :value="sync.sync_name" :label="sync.sync_name"></el-option>
                        </el-select> -->
                    </base-input>

                    <label class="form-control-label">{{ tt('param_type') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('param_type')" rules="required">
                        <el-select v-model="sapSyncParam.param_type" :placeholder="tt('param_type')">
                            <el-option value="output">output</el-option>
                            <el-option value="input">input</el-option>
                        </el-select>
                    </base-input>

                    <label class="form-control-label">{{ tt('data_type') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('data_type')" rules="required">
                         <el-select v-model="sapSyncParam.data_type" :placeholder="tt('data_type')">
                            <el-option value="json">json</el-option>
                            <el-option value="table">table</el-option>
                        </el-select>
                    </base-input>

                    <label class="form-control-label">{{ tt('source_type') }}</label>
                    <base-input :name="tt('source_type')">
                         <el-select v-model="sapSyncParam.source_type" :placeholder="tt('source_type')" clearable>
                            <el-option value="object">object</el-option>
                        </el-select>
                    </base-input>
                    
                    <label class="form-control-label" v-if="sapSyncParam.source_type == 'object'">{{ tt('source') }} <span class="text-danger">*</span></label>
                    <base-input v-if="sapSyncParam.source_type == 'object'" :name="tt('source')" :placeholder="tt('source')" v-model="sapSyncParam.source" rules="required">
                        <el-select v-model="sapSyncParam.source" :placeholder="tt('source')" filterable clearable remote :remote-method="searchObject" reserve-keyword>
                            <el-option v-for="object in tableObjects" :key="object.code" :value="object.code" :label="object.code"></el-option>
                        </el-select>
                    </base-input>

                    <label class="form-control-label">{{ tt('source_key') }}</label>
                    <base-input :name="tt('source_key')" :placeholder="tt('source_key')" v-model="sapSyncParam.source_key"></base-input>
                    
                    <label class="form-control-label">{{ tt('sap_ref') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('sap_ref')" :placeholder="tt('sap_ref')" v-model="sapSyncParam.sap_ref" rules="required"></base-input>

                    <label class="form-control-label">{{ tt('sap_key') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('sap_key')" :placeholder="tt('sap_key')" v-model="sapSyncParam.sap_key" rules="required"></base-input>
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                    <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                        <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                        <span v-else>
                            <span v-if="form.add">{{ tt('add') }}</span>
                            <span v-else>{{ tt('edit') }}</span>
                        </span>
                    </base-button>
                </template>
            </modal>
        </validation-observer>
        <!-- Mapping Start -->
        <modal :show.sync="paramsMapping.show" size="lg">
            <template slot="header">
                <h5 class="modal-title mb-0">{{ tt('sap_sync_param_mapping')}}</h5>
            </template>
            <base-button type="default" size="sm" class="float-right mb-2" @click="addMapping" :disabled="btnSave.onLoading">{{tt('add_new')}}</base-button>
            <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="paramsMapping.data">
                <el-table-column :label="tt('data_type')" :prop="tt('data_type')" min-width="100px" sortable>
                    <template v-slot="{row}">
                    <base-input :name="tt('data_type')" :placeholder="tt('data_type')">
                            <el-select v-model="row.data_type" :disabled="btnSave.onLoading">
                                <el-option value="string" :selected="row.data_type === 'string'" label="string"></el-option>
                            </el-select>
                        </base-input>
                    </template>
                </el-table-column>
                <el-table-column :label="tt('source_column')" :prop="tt('source_column')" min-width="120px" sortable>
                    <template v-slot="{row}">
                        <base-input :name="tt('source_column')" :placeholder="tt('source_column')" v-model="row.source_column">
                            <el-select v-model="row.source_column" filterable :disabled="btnSave.onLoading" clearable>
                                <el-option v-for="(column, key) in sourceColumns" :key="key" :value="column" :label="column"></el-option>
                            </el-select>
                        </base-input>
                    </template>
                </el-table-column>
                <el-table-column :label="tt('sap_column')" :prop="tt('sap_column')" min-width="100px" sortable>
                    <template v-slot="{row}">
                        <base-input :name="tt('sap_column')" :placeholder="tt('sap_column')" v-model="row.sap_column" :disabled="btnSave.onLoading"></base-input>
                    </template>
                </el-table-column>
                <el-table-column :label="tt('update')" :prop="tt('update')" min-width="100px" sortable>
                    <template v-slot="{row}">
                        <base-input :name="tt('update')">
                            <base-checkbox v-model="row.update" :disabled="btnSave.onLoading"></base-checkbox>
                        </base-input>
                    </template>
                </el-table-column>
                <el-table-column prop="action" width="50px">
                    <template slot-scope="scope">
                        <base-input>
                            <i class="fa fa-trash text-danger" @click="deleteMapping(scope.$index)"></i>
                        </base-input>
                    </template>
                </el-table-column>
            </el-table>
            <template slot="footer">
                <base-button type="secondary" @click="paramsMapping.show = false">{{ tt('close') }}</base-button>
                <base-button type="primary" v-on:click="saveMapping" :disabled="btnSave.onLoading">
                    <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                    <span v-else>
                        <span v-if="form.add">{{ tt('add') }}</span>
                        <span v-else>{{ tt('edit') }}</span>
                    </span>
                </base-button>
            </template>
        </modal>
        <!-- Mapping End -->
    </div>
    <noaccess v-else/>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import sapIntegration from '@/services/sap/sapIntegration.service';
    import tableDatabase from '@/services/general/tableDatabase.service';

    export default {        
        data() {
            return {  
                onLoad: true,      
                loadTimeout: null,  
                btnSave: {
                    onLoading: false
                },             
                form: {
                    add: true,
                    title: this.tt('add_sap_sync_param'),
                    show: false
                }, 
                table: {
                    search: (this.$route.query.search) ? this.$route.query.search : '',                    
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },        
                sapSyncParam: {},
                paramsMapping:{
                    show:false,
                    mapping:{},
                    data:[],
                    idParam:null,
                },
                sourceColumns:[],
                tableObjects:[],
                sapSyncs:[],
                sourceParams:null,
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            get() { 
                let context = this;               
                Api(context, sapIntegration.getParams(this.table.page.current_page, {search: this.table.search, sap_sync_id: this.$route.query.sap_sync_id})).onSuccess(function(response) {    
                    context.table.total = response.data.data.total;
                    context.table.data  = response.data.data.data;  
                    context.table.page  = response.data.data;  
                    context.onLoad      = false;      
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                })
                .call()
            },
            filter() {
                if(this.table.search != this.$route.query.search){
                    this.onLoad = true;
                    this.table.page.current_page = 1;
                    clearTimeout(this.loadTimeout);
                    this.loadTimeout = setTimeout(() => {
                        if(this.table.search == ''){
                            this.$router.push({path:'/sap/config-sap-sync-params', query:{sap_sync_id: this.$route.query.sap_sync_id}})
                        }else{
                            this.$router.push({path:'/sap/config-sap-sync-params', query:{sap_sync_id: this.$route.query.sap_sync_id,search:this.table.search}})
                        }
                        this.get()
                    }, 100);
                }
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    case 'mapping':
                            this.mapping(command.data.id, command.data.source);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.form.add = true;
                this.form.title = this.tt('add_sap_sync_param');
                this.form.show = true;
                this.sapSyncParam = {
                    sap_sync_id: this.$route.query.sap_sync_id
                };
            },
            edit(id) {
                this.errorMessage = null;
                let context = this;               
                Api(context, sapIntegration.showParam(id)).onSuccess(function(response) {
                    context.sapSyncParam = response.data.data;
                    context.form.add = false;
                    context.form.title = context.tt('edit_sap_sync_param');
                    context.form.show = true;                          
                })
                .call()        
            },
            save() {                      
                this.btnSave.onLoading = true;
                let api = null;
                let context = this;
                if (this.form.add) {
                    api = Api(context, sapIntegration.createParam(this.sapSyncParam));
                } else {{
                    api = Api(context, sapIntegration.editParam(this.sapSyncParam.id, this.sapSyncParam));
                }}
                api.onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.form.show = false;
                    context.get()
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                })
                .call();
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, sapIntegration.deleteParam(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                        }).call();
                    }
                })
            },            
            mapping(id, source) {
                let context = this;
                this.sourceParams = source;
                Api(context, sapIntegration.getParamMapping(id)).onSuccess(function(response) {
                    context.paramsMapping.data = response.data.data;
                    context.paramsMapping.data.forEach(function(data, index){
                        context.paramsMapping.data[index].update = (data.update == 1) ? true : false;
                    });              
                }).onError((e) => {
                    context.paramsMapping.data = [];
                    context.paramsMapping.show = true;
                    context.paramsMapping.idParam = id;
                }).onFinish(() =>{
                    context.paramsMapping.show  = true;
                    context.paramsMapping.idParam = id       
                    context.getTable()
                })
                .call()  
            },
            addMapping(){
                this.paramsMapping.data.push({
                    id:'',
                    data_type:'',
                    source_column:'',
                    sap_column:'',
                    update:0,
                })
            },
            deleteMapping(index){
                this.paramsMapping.data.splice(index, 1)
            },
            saveMapping(){
                let context = this;

                this.confirmDialog(this.tt('confirm_save')).then((result) => {
                    let context = this;
                    for(var i = 0;i < this.paramsMapping.data.length;i++){
                        if(!this.paramsMapping.data[i].data_type || !this.paramsMapping.data[i].source_column || !this.paramsMapping.data[i].sap_column){
                            context.$notify({
                                message: 'Data Type, Source Column and Sap Key are Mandatory ',
                                type: 'danger',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            return;
                        }
                    }
                    this.btnSave.onLoading = true;
                    if (result.value) {
                        Api(context, sapIntegration.saveParamMapping( this.paramsMapping.idParam,{data:this.paramsMapping.data})).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.btnSave.onLoading = false;
                            context.get();
                        }).call();
                    }
                })
            },
            getTable(){
                let context = this;
                Api(context, tableDatabase.getTable({code: this.sourceParams})).onSuccess((response) =>{
                    context.sourceColumns = response.data.data;
                }).call()
            },
            searchObject(query){
                if(query){
                    setTimeout(() => {
                        let context = this;
                        Api(context, sapIntegration.getTableObjects({search:query})).onSuccess((response) =>{
                            context.tableObjects = response.data.data
                        }).onError((e) =>{
                            context.tableObjects = [];
                        }).call()
                    })
                }
            },
            searchSync(query){
                if(query){
                    setTimeout(() => {
                        let context = this;
                        Api(context, sapIntegration.configSync(null,{search:query})).onSuccess((response) =>{
                            context.sapSyncs = response.data.data.data.data
                        }).onError((e) =>{
                            context.sapSyncs = [];
                        }).call()
                    })
                }
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            backPage(){
                this.$router.go(-1);
            }
        }   
    };
</script>
